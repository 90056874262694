import styled from "styled-components"
import { colors } from "./variables"

export const NewsStyle = styled.div`
  .container {
    height: 100%;
    min-height: 60vh;
    padding: 20vh 10vw 20vh 10vw;
    background-size: cover;
    background-position-x: center;
    color: #ffffff;

    & > * {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    .title {
      color: #ffffff;
      text-transform: uppercase;
      font-family: "Barlow", sans-serif;
      font-size: 4rem;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3;
      letter-spacing: 1.04px;
      margin-bottom: 25px;
    }

    .flex-container {
      list-style: none;
      padding: 0;

      .flex-item {
        margin: 15px 0 15px 0;
        padding: 10px;
        background-color: rgba(102, 102, 102, 0.5);
        transition: background-color 0.3s ease;
        -o-transition: background-color 0.3s ease;
        -moz-transition: background-color 0.3s ease;
        -webkit-transition: background-color 0.3s ease;

        &:hover {
          background-color: ${colors.darkGreyT};
        }

        a {
          display: grid;
          grid-template-columns: 1fr 9fr;
          grid-template-rows: 1fr 1fr;
          grid-column-gap: 5%;
          align-items: center;

          img {
            height: 15vh;
            min-height: 125px;
            grid-column-start: 1;
            grid-column-end: 1;
            grid-row-start: 1;
            grid-row-end: 3;
          }

          h2 {
            font-size: 3.2rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .container {
      padding: 20vh 30px 20vh 30px;
    }
    .container .title {
      font-size: 2.4rem;
      line-height: 30px;
      margin-bottom: 12.5px;
    }
    .container .flex-container {
      padding: 0;
    }
    .container .flex-container .flex-item {
      margin-left: 0;
      margin-right: 0;
    }
    .container .flex-container .flex-item a img {
      align-self: start;
      height: 12vh;
      min-height: 50px;
    }
    .container .flex-container .flex-item a h2 {
      font-size: 1.2rem;
    }
    .container .flex-container .flex-item a p {
      font-size: 1rem;
    }
  }
`

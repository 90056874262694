import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"
import ScrollAnimation from "react-animate-on-scroll"
import { NewsStyle } from "../styles/news"

const Noticias = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCarouselNews(sort: { fields: date, order: DESC }) {
        edges {
          node {
            name
            slug
            date(formatString: "D [de] MMMM [de] YYYY", locale: "pt")
            image {
              file {
                url
              }
            }
          }
        }
      }
      dataJson {
        projectos {
          img {
            publicURL
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="Notícias"
        image="/images/projectos.jpg"
        imageWidth="1200"
        imageHeight="628"
        pathname="/noticias"
        description="Sicarpo - Fique a par das últimas novidades."
      />
      <NewsStyle>
        <div
          className="container"
          style={{
            backgroundImage: `url(${data.dataJson.projectos.img.publicURL})`,
          }}
        >
          <ScrollAnimation
            animateIn="fadeInDown"
            offset={0}
            duration={1.2}
            animateOnce={true}
          >
            <h1 className="title">Notícias</h1>
          </ScrollAnimation>
          <ol className="flex-container">
            {data.allContentfulCarouselNews.edges.map((edge, index) => {
              return (
                <li className="flex-item" key={index}>
                  <Link to={`/noticias/${edge.node.slug}`}>
                    <img src={edge.node.image.file.url} alt={edge.node.name} />
                    <h2>{edge.node.name}</h2>
                    <p>{edge.node.date}</p>
                  </Link>
                </li>
              )
            })}
          </ol>
        </div>
      </NewsStyle>
    </>
  )
}

export default Noticias
